// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const signInConfig = {
  WEB_PROTOCOL: 'https://',
  DOMAIN_NAME: 'cbm-admin-dev.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '4efofql74344qlibvqoag249d4',
  RESPONSE_TYPE: 'code',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'http://localhost:4200/login',
  REDIRECT_SIGN_OUT: 'http://localhost:4200/',
  SCOPE: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
}

export const environment = {
  production: false,
  // API FOR CBM - NEO4J
  // https://n6toe4u4j8.execute-api.ap-southeast-1.amazonaws.com
  // https://il8zpfm9r1.execute-api.ap-southeast-1.amazonaws.com/dev
  // https://dev.cbm-api.meco-soldel.com  for dev
  // https://sqt.cbm-api.meco-soldel.com  for sqt
  // https://uat.cbm-api.meco-soldel.com  for uat
  // https://cbm-api-mock.meco-soldel.com for mock
  hostURL: 'https://cbm-api-mock.meco-soldel.com',


  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?response_type=${signInConfig.RESPONSE_TYPE}&client_id=${signInConfig.CLIENT_ID}&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`

};
